@font-face {
  font-family: ingiri;
  src: url('./fonts/bpg_ingiri_arial_0.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: ninom;
  src: url('./fonts/bpg_nino_mtavruli_normal.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: ninomb;
  src: url('./fonts/bpg_nino_mtavruli_bold.ttf') format('truetype');
  font-display: swap;
}

@font-face {
  font-family: roboto;
  src: url('./fonts/Roboto-Regular.ttf') format('truetype');
  font-display: swap;
}